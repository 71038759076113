<template>
  <clients></clients>
</template>

<script>
import Clients from "@/components/company/Clients";
export default {
  components: {
    Clients
  }
};
</script>
