<template>
  <div class="flex flex-col">
    <div class="flex items-center mobile:flex-wrap mobile:justify-between mb-4">
      <div class="flex mobile:w-full mobile:justify-between mobile:mb-2">
        <h2 class="mr-3">Kliendid</h2>
        <div class="mr-3">
          <router-link to="/clients/new">
            <button class="alt-button-green">
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa uus</span>
            </button>
          </router-link>
        </div>
      </div>
      <div
        class="mobile:ml-0 ml-auto mobile:w-full flex items-center bg-offwhite rounded-full duration-100"
        :class="focusSearch ? 'shadow-outline' : 'shadow'"
      >
        <div class="w-1/12 pl-2">
          <span class="">
            <img src="/bc21/search.svg" class="h-4 w-4" />
          </span>
        </div>
        <input
          type="text"
          v-model="filterText"
          @input="debounceSearch"
          placeholder="Otsi klienti"
          class="shadow-none focus:shadow-none hover:shadow-none rounded-full"
          @focus="focusSearch = true"
          @blur="focusSearch = false"
        />
      </div>
    </div>
    <div
      v-if="clientsLoaded"
      class="w-full flex flex-col bg-white"
      :class="clients.results.length === 0 ? 'h-full' : ''"
    >
      <div
        v-if="clients.results.length === 0"
        class="mx-auto my-auto py-2 px-8 flex flex-col justify-center items-center"
      >
        <div class="flex justify-center mb-4">
          <img src="/favicon.ico" class="h-24 w-24" />
        </div>
        <h3 class="mb-2 flex">Kliente pole</h3>
        <div class="flex">
          <router-link to="/clients/new">
            <button class="new-button-green">
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa uus klient</span>
            </button>
          </router-link>
        </div>
      </div>
      <router-link
        :to="`/clients/${client.id}`"
        v-for="(client, index) in clients.results"
        :key="client.id"
      >
        <company-client-card
          :class="[
            index === 0 ? 'rounded-t-xl' : '',
            index === clients.results.length - 1 ? 'rounded-b-xl' : ''
          ]"
          :client="client"
          :is-first="index === 0"
        >
          <span
            class="typcn typcn-plus bg-green rounded-full p-2 text-white"
          ></span>
          <span class="pl-2 pr-3">Lisa klient</span>
        </company-client-card>
      </router-link>

      <paginator
        class="self-center mt-4"
        v-if="clients.count > 1"
        :page="page"
        :count="clients.count"
        @pageChange="pageChange"
      ></paginator>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import CompanyClientCard from "@/components/company/clients/CompanyClientCard";
import debounce from "lodash/debounce";
import Paginator from "@/components/reusable/Paginator";

export default {
  name: "Clients",
  components: { Paginator, CompanyClientCard },
  mixins: [RequestHandler],
  data() {
    return {
      clientsLoaded: false,
      clients: [],
      filterText: "",
      focusSearch: false,
      page: 1
    };
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.retrieveClients();
    },
    showAddedClient(clientId) {
      this.addedClientId = clientId;
      this.retrieveClients();
    },
    clientDeleted() {
      this.retrieveClients();
    },
    retrieveClients() {
      this.clientsLoaded = false;
      this.clients = [];
      let url =
        "company/" +
        this.$store.state.companyData.activeCompany.uuid +
        "/clients/";
      let first = true;
      if (this.filterText !== "") {
        first = false;
        url += "?filter=" + this.filterText;
      }
      if (first) {
        url += "?page=" + this.page;
      } else {
        url += "&page=" + this.page;
      }
      this.apiRequest(url, "get", true).then(res => {
        this.clients = res.data;
        this.clientsLoaded = true;
      });
    },
    debounceSearch: debounce(function() {
      this.retrieveClients();
    }, 500)
  },
  watch: {
    clients() {
      if (this.addedClientId !== null) {
        this.activeClient = this.addedClientId;
        this.addViewActive = false;
        this.clientDetailViewActive = true;
      }
    },
    filterText() {
      this.page = 1;
    }
  },
  mounted() {
    this.retrieveClients();
  }
};
</script>
