<template>
  <div
    class="w-full flex flex-col items-center duration-100 bg-white justify-center cursor-pointer mobile:flex-col flex-wrap"
  >
    <div
      class="flex w-full hover:bg-offwhite hover:bg-opacity-50 py-5 mobile:flex-col px-3"
    >
      <div
        class="w-3/12 flex justify-start items-center mobile:w-full mobile:mb-2"
      >
        <span
          class="flex typcn table-icon mr-2"
          :class="client.is_company ? 'typcn-briefcase' : 'typcn-user'"
        ></span>
        <div class="flex-auto flex font-medium">
          <span> {{ client.name }}</span>
        </div>
      </div>
      <div
        class="w-3/12 flex justify-start items-center mobile:w-full mobile:mb-2"
      >
        <div class="flex mr-2">
          <div
            class="flex rounded-full w-7 h-7 border-green border-2 items-center justify-center leading-none"
          >
            <span class="p-1"
              ><img src="/bc21/phone.svg" class="h-4 filter-to-green"
            /></span>
          </div>
        </div>
        <div class="flex-auto flex">
          <span>
            {{ client.telephone_number ? client.telephone_number : "-" }}</span
          >
        </div>
      </div>
      <div
        class="w-3/12 flex justify-start items-center mobile:w-full mobile:mb-2"
      >
        <div class="flex mr-2">
          <div
            class="flex rounded-full border-green border-2 items-center justify-center leading-none h-7 w-7"
          >
            <span class="p-1"
              ><img src="/bc21/mail.svg" class="h-4 filter-to-green"
            /></span>
          </div>
        </div>
        <div class="flex-auto flex">
          <span> {{ client.email }}</span>
        </div>
      </div>
      <div
        class="w-3/12 flex justify-start flex-row items-center mobile:w-full mobile:mb-3"
      >
        <div class="flex mr-2">
          <div
            class="flex border-2 border-green rounded-full justify-center items-center h-7 w-7 leading-none"
          >
            <img src="/bc21/placeholder.svg" class="h-4 filter-to-green" />
          </div>
        </div>
        <span class="truncate text-sm pl-2">{{
          client.address === null ? "-" : client.address.long_address
        }}</span>
      </div>
    </div>

    <div class="w-full bg-offwhite rounded-full h-1"></div>
  </div>
</template>

<script>
export default {
  name: "CompanyClientCard",
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isFirst: Boolean
  }
};
</script>
